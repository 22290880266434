<template>
  <div class="navbar">
    <nav id="navbarEl">
      <div class="logo" @click="scrollToBlock('main')">
        <img src="@/assets/hover-jonesy.png" alt="jonesy">
        <h3>JONESY</h3>
      </div>
      <ul class="info">
        <li>
          <span class="info_title">Market Cap</span>
          <span class="info_data">{{ formatCurrency(tokenMarketCap) }}</span>
        </li>
        <li>
          <span class="info_title">24h Volume</span>
          <span class="info_data">{{ formatCurrency(tokenVolume) }}</span>
        </li>
        <li>
          <span class="info_title">Price</span>
          <span class="info_data">{{ formatCurrency(tokenPrice) }}</span>
        </li>
      </ul>
      <ul class="navigation">
        <li ref="navHome" @click="scrollToBlock('main')">
          HOME
        </li>
        <li ref="navAbout" @click="scrollToBlock('about')">
          ABOUT
        </li>
        <li ref="navTokenomics" @click="scrollToBlock('tokenomics')">
          TOKENOMICS
        </li>
        <li ref="navMemes" @click="scrollToBlock('memes')">
          MEMES
        </li>
      </ul>
      <button class="buy-now" @click="scrollToBlock('swap')">
        BUY NOW
      </button>
      <div class="sound-btn">
        <svg v-if="isMusicOn" @click="musicOff" width="30" height="30" viewBox="0 0 24 24" id="music-note" xmlns="http://www.w3.org/2000/svg">
          <path fill="none" d="M0 0h24v24H0V0z"/>
          <path d="M12 5v8.55c-.94-.54-2.1-.75-3.33-.32-1.34.48-2.37 1.67-2.61 3.07-.46 2.74 1.86 5.08 4.59 4.65 1.96-.31 3.35-2.11 3.35-4.1V7h2c1.1 0 2-.9 2-2s-.9-2-2-2h-2c-1.1 0-2 .9-2 2z" stroke-width="0" stroke="black" fill="#0288d1"/>
        </svg>
        <svg v-else @click="musicOn" width="30" height="30" viewBox="0 0 24 24" id="music-off" xmlns="http://www.w3.org/2000/svg">
          <path fill="none" d="M0 0h24v24H0V0z"/>
          <path d="M14 9.61V7h2c1.1 0 2-.9 2-2s-.9-2-2-2h-3c-.55 0-1 .45-1 1v3.61l2 2zM5.12 3.56c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l8.29 8.3v.28c-.94-.54-2.1-.75-3.33-.32-1.34.48-2.37 1.67-2.61 3.07-.46 2.74 1.86 5.08 4.59 4.65 1.96-.31 3.35-2.11 3.35-4.1v-1.58l5.02 5.02c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L5.12 3.56z" fill="#0288d1"/>
        </svg>
      </div>

      <audio loop>
        <source src="@/assets/music.mp3" type="audio/mpeg">
      </audio>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import axios from "axios";

const isMusicOn = ref<boolean>(false)

const tokenMarketCap = ref<'...' | number>('...')
const tokenVolume = ref<'...' | number>('...')
const tokenPrice = ref<'...' | number>('...')
const navItems: { [key: string]: HTMLLIElement | null } = {}

const navHome = ref<HTMLLIElement | null>(null)
const navAbout = ref<HTMLLIElement | null>(null)
const navTokenomics = ref<HTMLLIElement | null>(null)
const navGenerator = ref<HTMLLIElement | null>(null)
const navMemes = ref<HTMLLIElement | null>(null)

function musicOff() {
  isMusicOn.value = false
  const audioEl: HTMLAudioElement | null = document.querySelector("audio")
  if (audioEl) {
    audioEl.pause()
  }
}
function musicOn() {
  isMusicOn.value = true
  const audioEl: HTMLAudioElement | null = document.querySelector("audio")
  if (audioEl) {
    audioEl.play()
  }
}

async function fetchTokenInfo() {
  const response = await axios.get('https://api.dexscreener.com/latest/dex/pairs/solana/HLPgmNT9v3fKL5bEbhEHctxWB13HfBqs252kmQj9Bv7C')

  tokenMarketCap.value = parseFloat(response.data.pair.fdv)
  tokenVolume.value = parseFloat(response.data.pair.volume.h24)
  tokenPrice.value = parseFloat(response.data.pair.priceUsd)
}

function formatCurrency(number: number | '...') {
  if (number === '...') return '$...'

  let numberStr = number.toString();
  let parts = numberStr.split('.');
  let integerPart = parts[0];
  let decimalPart = parts[1] || '';
  let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let formattedNumber = decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;

  return `$${formattedNumber}`;
}

function scrollToBlock(blockId: string) {
  const targetBlock: HTMLElement | null = document.getElementById(blockId);

  if (targetBlock) {
    const rect = targetBlock.getBoundingClientRect();
    const offsetTop = window.pageYOffset + rect.top - 100;

    window.scrollTo({ top: offsetTop, behavior: "smooth" });
  }
}

function handleIntersection(entries: IntersectionObserverEntry[]) {
  entries.forEach(entry => {
    const targetId = entry.target.id;
    const navItem = navItems[targetId];

    if (entry.isIntersecting) {
      navItem?.classList.add('observed');
    } else {
      navItem?.classList.remove('observed');
    }
  });
}

onMounted(() => {
  fetchTokenInfo();

  const observer = new IntersectionObserver(handleIntersection, {
    threshold: [0.7]
  });

  const targetBlocks = ['main', 'about', 'tokenomics', 'generator', 'memes'];
  targetBlocks.forEach(blockId => {
    const targetBlock = document.getElementById(blockId);
    if (targetBlock) {
      observer.observe(targetBlock);
    }
  });

  navItems['main'] = navHome.value;
  navItems['about'] = navAbout.value;
  navItems['tokenomics'] = navTokenomics.value;
  navItems['generator'] = navGenerator.value;
  navItems['memes'] = navMemes.value;
});

</script>

<style lang="sass" scoped>
.navbar
  position: fixed
  top: 0
  width: 100%
  height: 80px
  z-index: 100
  display: flex
  align-items: center
  justify-content: center
  background-color: white
nav
  width: 100%
  max-width: 1350px
  height: 100%
  background-color: transparent
  display: flex
  align-items: center
  justify-content: space-between
  font-size: 20pt
ul
  display: flex
  list-style-type: none
  margin: 0
  padding: 0
.logo
  font-size: 25pt
  display: flex
  align-items: center
  gap: 10px
  img
    flex: 2
    height: 80px
    left: -100%
    top: -50%
.info
  display: flex
  gap: 30px
  & li
    display: flex
    flex-direction: column
    color: black
    transition: all 0.3s ease
  &_title
    font-size: 10pt
    font-weight: 600
    line-height: 5px
    letter-spacing: 0.5px
    color: #2e9fda
  &_data
    font-size: 15pt
    font-weight: 500
    line-height: 30px
    letter-spacing: 0.5px
.navigation
  display: flex
  align-items: center
  justify-content: center
  gap: 30px
  & li
    color: black
    transition: all 0.2s ease-in-out
    font-size: 18pt
    font-weight: 600
    position: relative
    &::after
      content: ''
      background-color: black
      width: 0
      height: 3.5px
      position: absolute
      bottom: -4px
      left: 0
      transition: all 0.2s ease-in-out
    &:hover::after
        width: 100%
    &.observed
      &::after
        content: ''
        background-color: black
        width: 100%
        height: 3.5px
        position: absolute
        bottom: -4px
        left: 0
.buy-now
  justify-self: flex-end
  padding: 10px 10px 5px
  font-size: 18pt
  font-family: 'FortniteFont', sans-serif
  background-color: #0288d1
  color: white
  border-radius: 5px
  border: 5px solid #35b2f6
  transition: all 0.3s ease
.sound-btn
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%
  background-color: white
  padding: 5px
  border: 3px solid #0288d1
@media (max-width: 1400px)
  .logo, .info
    display: none
  nav
    justify-content: center
    gap: 30px
@media (max-width: 600px)
  nav
    gap: 20px
  .navigation
    gap: 10px
    & li
      font-size: 13pt
  .buy-now
    font-size: 13pt
  .sound-btn
    width: 40px
    height: 40px
@media (max-width: 400px)
  nav
    gap: 10px
  .navigation
    gap: 5px
    & li
      font-size: 13pt
  .buy-now
    font-size: 13pt
  .sound-btn
    width: 35px
    height: 35px
</style>
